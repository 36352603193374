import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
// Libs
import { useSnackbar } from 'notistack';
// Mui
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import ButtonGroup from '@mui/material/ButtonGroup';
import { updateModelAttributes } from '@/services/SocketModelUpdate';
import { useAppSelector } from '@/hooks/useAppSelector';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// Context
import UserContext, { UserContextType } from '../contexts/UserContext';
// Sockets
import Sockets from '../services/SocketService';
// Models
import BaseModel from '../@types/models/BaseModel';
import ToteModel from '../@types/models/ToteModel';
import UserModel from '../@types/models/UserModel';
// Components
import VideoPlayer from '../components/VideoPlayer';
import Page from '../components/Page';
import HorseBetButton, {
  BET_ODD,
  BET_EVEN,
} from '../components/HorseBetButton';
import HorsesPopover from '../components/HorsesPopover';
import SportEventsPopover from '../components/SportEventsPopover';
import SportEventsBetConfirmPopover from '../components/SportEventsBetConfirmPopover';
// SVG
import {
  ExpandIconSvg,
  CollapseIconSvg,
  ResetIconSvg,
  EvolutionGamesSvg,
  RealTimeGamesSvg,
  WEGamesSvg,
  DotSVG,
  ClosePopSVG,
  CrownPopSVG,
  TimeIconSvg,
} from '../shared/svg';
// Api
import { apiGetCurrentUser } from '../api/UserApi';
import {
  apiGetSportEventModels,
  apiPlaceRaceCardBet,
  RaceEventType,
  RaceBetType,
  RaceCardBetType,
  apiGetTote,
} from '../api/RaceCardApi';
import RaceVideo from './betting/video';
import { KeyboardArrowDown } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { List, ListItem, ListItemButton } from '@mui/material';
import { relative } from 'path';
import { MathFloor } from '@/utils/formatNumberFloor';

// TODO move this to MUI-Theme
const themeColor =
  process.env.REACT_APP_THEME === 'fairplay' ? '#2F594D' : '#384766';
const MAX_AMOUNT = 25000;
const DEBUG_STREAM_SOURCE: null | string = null;
// We are refreshing the page just in case if sockets stop working.
const REFRESH_EVERY_SECONDS = 30;

const TrendsCard = styled(Card)(({ theme }) => ({
  zIndex: 1,
  maxWidth: 660,

  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  // margin: 8,
  padding: '2px 10px 10px 10px',
  // marginTop: 4,
  backgroundColor: 'transparent',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  // border: `1px solid ${themeColor}`,
  // background: `${theme.palette.background.default}A8`,
  backdropFilter: 'blur(2px)',
  display: 'flex',
  background: 'none',

  // borderTopLeftRadius:'10px !important' ,
  // borderTopRightRadius:'10px !important',
  // borderRadius:'0px',
  // borderBottom:'0px',

  // flex:'1',
}));

const TrendsHead = styled(Card)(({ theme }) => ({
  display: 'flex',
  background: '#1D222B',
  borderTopLeftRadius: '8px !important',
  borderTopRightRadius: '8px !important',
  borderRadius: '0px',
  height: '34px',
  width: '100%',
  padding: '5px',
}));

const TrendsBody = styled(Card)(({ theme }) => ({
  display: 'flex',
  background: 'transparent',
  width: '100%',
  padding: '0px',
  borderRadius: '0px',
  overflow: 'auto',
}));

const TrendsView = styled(Card)(({ theme }) => ({
  zIndex: 1,
  maxWidth: 660,
  flexShrink: 0,
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  // margin: 8,
  // padding: 2,
  // marginTop: 4,
  backgroundColor: 'transparent',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  border: `1px solid #38404D`,
  background: `${theme.palette.background.default}A8`,
  backdropFilter: 'blur(2px)',
  display: 'flex',
  flexDirection: 'column',
  borderTopLeftRadius: '10px !important',
  borderTopRightRadius: '10px !important',
  borderRadius: '0px',
  borderBottom: '0px',
}));

const BorderCard = styled(Card)(({ theme }) => ({
  zIndex: 1,
  maxWidth: 660,
  // flexShrink: 0,
  width: '100%',
  position: 'relative',
  overflow: 'inherit',
  // margin: 8,
  padding: 10,
  // marginTop: 4,
  backgroundColor: 'transparent',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: 8,
  // border: `1px solid ${themeColor}`,
  // background: `${theme.palette.background.default}A8`,
  // backdropFilter: 'blur(2px)',
  display: 'flex',
  backgroundImage: 'none',
  // flex:'1',
}));

const XOCard = styled(Card)(({ theme }) => ({
  zIndex: 1,
  maxWidth: 660,
  // flexShrink: 0,
  width: '100%',
  position: 'relative',
  overflow: 'inherit',
  // margin: 8,
  padding: 5,
  marginTop: 4,
  backgroundColor: 'transparent',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: 10,
  border: `1px solid ${themeColor}`,
  background: `${theme.palette.background.default}A8`,
  backdropFilter: 'blur(2px)',
  display: 'flex',
  gap: '4px',
  // flex:'1',
}));

const BetCard = styled(Card)(({ theme }) => ({
  zIndex: 1,
  maxWidth: 660,
  // flexShrink: 0,
  width: '100%',
  position: 'relative',
  overflow: 'inherit',
  // margin: 8,
  padding: '4px 4px 8px 4px',
  marginTop: 4,
  backgroundColor: 'transparent',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: 10,
  border: `1px solid ${themeColor}`,
  background: `${theme.palette.background.default}A8`,
  backdropFilter: 'blur(2px)',
  display: 'flex',
  flexDirection: 'column',

  // flex:'1',
}));

// This must be fix on a global state when header is added.
const ScreenDiv = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'column-reverse',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: 0,
  padding: 0,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundImage: 'none',
}));

const StyledRaceInfoButtonBase = styled(ButtonBase)(({ theme }) => ({
  color: 'white',
  width: '100%',
  height: '100%',
  // border: `1px solid rgba(255, 255, 255, 0.24)`,
  // background: `${theme.palette.background.default}A8`,
  // borderRadius: 8,
  // padding: '0 8px',
  // backdropFilter: 'blur(2px)',
}));

const StyledDividerDiv = styled('div')(({ theme }) => ({
  width: '100%',
  height: '1px',
  backgroundColor: '#FFFFFF29',
  borderRight: '1px solid #00000069',
  marginTop: '0px !important',
}));

const betCardTransparencyFactor = 'C8';

const GameOddEven = () => {
  const { module } = useParams();
  const amounts = ['10', '50', '100', '500'];
  const { userModel } = useContext<UserContextType>(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { userInfo } = useAppSelector(state => state.auth);
  // ----------------------------------------------------------------------

  interface CircleProps {
    type: 'X' | 'O' | 'R' | 'N'; // Define the type for the 'type' prop
    currentEvent?: RaceEventType;
  }
  // State.
  // Anchors for popups.
  const [horsesAnchorEl, setHorsesAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [eventsAnchorEl, setEventsAnchorEl] = useState<null | HTMLElement>(
    null
  );
  // State represents the video full screen or adjusted screen.
  const [isExpandedVideoStream, setIsExpandedVideoStream] =
    useState<boolean>(false);
  // This state represent the refresh time.
  const [refreshTime, setRefreshTime] = useState(Date.now());
  // State to place a bet.
  const [betAmount, setBetAmount] = useState<string>(amounts[0]);
  const [stopBet, setStopBet] = useState(false);
  const [resultPopover, setResultPopover] = useState(false);
  const [betplacedPopover, setBetplacedPopover] = useState(false);

  const [placeRaceBet, setPlaceRaceBet] = useState<null | RaceBetType>(null);
  // Model, separately tote and events.
  const [toteModels, setToteModels] = useState<null | Array<ToteModel>>(null);
  const [sportEventModels, setSportEventModels] =
    useState<null | Array<RaceEventType>>(null);
  const [activeEventModel, setActiveEventModel] = useState<any>(null);
  const [stopEventModel, setStopEventModel] = useState<any>(null);
  const [trendStatsPopup, setTrendStatsPopup] = useState<any>(false);

  // ----------------------------------------------------------------------
  // Logic related to the state.

  // Based on the current state we have logic that find the current event.
  // const activeEventModel = (
  //   sportEventModels && sportEventModels.find((event: RaceEventType): boolean => event.currentFlag === 'Y') || null
  // );
  // Then check if the event can be used to place a bet.
  // Sometimes events can has a current flag but not be ready to place a bet.
  const isActiveEventOpenToBet =
    activeEventModel &&
    activeEventModel.startBetFlag === 'Y' &&
    activeEventModel.stopBetFlag !== 'Y' &&
    activeEventModel.eventStatus !== 'FINAL' &&
    !activeEventModel.eventResult;
  // Stream can be still active, but betting closed.
  const activeStreamSource = DEBUG_STREAM_SOURCE;
  // Select tote model related to active event.
  const selectedToteModel =
    toteModels && activeEventModel && isActiveEventOpenToBet
      ? toteModels.find(
          (toteModel: ToteModel) => toteModel.id === activeEventModel.id
        )
      : null;

  console.log('current state', {
    activeEventModel,
    isActiveEventOpenToBet,
    activeStreamSource,
    selectedToteModel,
  });

  useEffect(() => {
    // debugger;
    if (!activeEventModel && sportEventModels) {
      // debugger;
      const currentEvent =
        (sportEventModels &&
          sportEventModels.find(
            (event: RaceEventType): boolean => event.stopBetFlag === 'N'
          )) ||
        null;
      setActiveEventModel(currentEvent);
    }
  }, [sportEventModels, activeEventModel]);

  const onChangeEvent = (event: any) => {
    setActiveEventModel(event);
  };
  // ----------------------------------------------------------------------
  // Functions.

  const updateToteModels = (toteModelData: ToteModel) => {
    if (toteModelData) {
      const newNoteModels = [...(toteModels || [])];
      let updateToteModelIndex = newNoteModels.findIndex(
        (toteModel: ToteModel) => toteModel.id === toteModelData.id
      );
      if (updateToteModelIndex !== -1) {
        newNoteModels[updateToteModelIndex] = Object.assign(
          newNoteModels[updateToteModelIndex],
          toteModelData
        );
      } else {
        newNoteModels.push(toteModelData);
      }
      setToteModels(newNoteModels);
    }
  };

  const eventFromSocketListener = (socketModelData: BaseModel) => {
    console.log('SOCKETMODELDATA', socketModelData);
    if (socketModelData.typename === 'tote') {
      updateToteModels(socketModelData as ToteModel);
    } else if (
      socketModelData.typename === 'event' ||
      socketModelData.typename === 'sportevent'
    ) {
      let updateSportEventModel = (sportEventModels || []).find(
        sportEventModel => sportEventModel.id === socketModelData.id
      );
      if (updateSportEventModel) {
        const newEventModelArray = [...sportEventModels];
        updateSportEventModel = Object.assign(
          updateSportEventModel,
          socketModelData
        );
        const sortEventsByTime = newEventModelArray.sort(
          (a: RaceEventType, b: RaceEventType) =>
            a.startTime.localeCompare(b.startTime)
        );
        //const currentEvent = (sortEventsByTime && sortEventsByTime.find((event: RaceEventType): boolean => event.stopBetFlag === 'N') || null);
        //setActiveEventModel(currentEvent);
        console.log(sportEventModels, 'for socket');
        setSportEventModels(sortEventsByTime);

        //setStopBet(true);
        //setResultPopover(true);
      }
      if (socketModelData?.eventStatus === 'CLOSED') {
        setActiveEventModel(null);
      }

      if (socketModelData?.eventStatus === 'CLOSED') {
        setStopBet(true);
        setStopEventModel(updateSportEventModel);
      }

      if (socketModelData.eventResult) {
        debugger;
        setResultPopover(true);
        setStopEventModel(updateSportEventModel);
      }
    }
  };

  const fetchRaceCards = useCallback(
    async (useEnqueueSnackbar = true) => {
      const events = await apiGetSportEventModels(
        module,
        useEnqueueSnackbar ? enqueueSnackbar : null
      );
      if (events) {
        const sortEventsByTime = events.sort(
          (a: RaceEventType, b: RaceEventType) =>
            a.startTime.localeCompare(b.startTime)
        );
        setSportEventModels(sortEventsByTime);

        // After a new card, fetch the tote.
        fetchTote();
      }
    },
    [enqueueSnackbar]
  );

  const fetchTote = useCallback(
    async (useEnqueueSnackbar = true) => {
      if (activeEventModel) {
        const data = await apiGetTote(activeEventModel.id);
        updateToteModels(data);
      } else {
        console.log('fetchTote::activeEventModel is empty');
      }
    },
    [activeEventModel, enqueueSnackbar]
  );

  const getDotSVG = (green: boolean) => (
    <span style={{ color: green ? '#66B15F' : '#FF6161', height: '18px' }}>
      <DotSVG />
    </span>
  );

  // ----------------------------------------------------------------------
  // Handlers.

  const handleOpenRaceCardsClick = (event: React.MouseEvent<HTMLElement>) => {
    setHorsesAnchorEl(event.currentTarget);
  };

  const handleCloseRaceCards = () => {
    setHorsesAnchorEl(null);
  };

  const handleOpenEventsClick = (event: React.MouseEvent<HTMLElement>) => {
    fetchRaceCards();
    setEventsAnchorEl(event.currentTarget);
  };

  const handleCloseEvents = () => {
    setEventsAnchorEl(null);
  };

  const handleReset = () => {
    setBetAmount(amounts[0]);
  };

  const handleInputAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      if (event.target.value === '') {
        setBetAmount(event.target.value);
      } else {
        let n = Number(event.target.value || '1');
        if (n < 1) n = 1;
        // else if (n > MAX_AMOUNT) {
        //   enqueueSnackbar(
        //     `The bet amount must be a minimum of 10 and maximum ${MAX_AMOUNT}.`,
        //     {
        //       variant: 'error',
        //     }
        //   );
        //   return false;
        // }
        setBetAmount(String(n));
      }
    }
  };

  const changeState = () => {
    setBetplacedPopover(false);
  };

  const handleSendBet = async (raceBet: RaceBetType) => {
    console.log('handleSendBet', raceBet);
    setPlaceRaceBet(null);
    const response = await apiPlaceRaceCardBet(
      raceBet.programId,
      raceBet.eventId,
      raceBet,
      enqueueSnackbar
    );
    if (response && response.status == 'OK') {
      //setBetplacedPopover(true);
      //setTimeout(changeState, 3000);
      enqueueSnackbar(
        `${raceBet.amount} ${raceBet.currency} <${raceBet.type}> bet placed successfully!`,
        { variant: 'success' }
      );
    }
  };

  const validatingBetAmount = (val: number) => {
    if (val < 10) {
      enqueueSnackbar('The bet amount must be a minimum of 10.', {
        variant: 'error',
      });
      return false;
    }
    if (val % 10 !== 0) {
      enqueueSnackbar('The bet amount must be a multiple of 10.', {
        variant: 'error',
      });
      return false;
    }
    return true;
  };

  const handlePlaceBet = async (
    raceCardBetType: RaceCardBetType,
    paramUserModel: null | UserModel = null
  ) => {
    const selectedWalletId = (paramUserModel || userModel)?.selectedWalletId;
    if (selectedWalletId) {
      if (activeEventModel) {
        if (validatingBetAmount(Number(betAmount))) {
          const data: RaceBetType = {
            type: raceCardBetType,
            poolName: 'WIN',
            runners: raceCardBetType === BET_ODD ? 1 : 2,
            startTime: activeEventModel.startTime,
            //
            venueId: activeEventModel.venueId,
            venueName: activeEventModel.venueName,
            programId: activeEventModel.programId,
            eventId: activeEventModel.id,
            eventName: activeEventModel.name.replace('Race', 'Event'),
            //
            userId: selectedWalletId,
            sessionId: 'f01b3d72-ae49-41a0-b85f-678f78e14968',
            amount: Number(betAmount),
            currency: 'PHP',
          };
          setPlaceRaceBet(data);
        }
      } else {
        // Internal error that shall never happen.
        enqueueSnackbar('To place a bet event must be selected', {
          variant: 'error',
        });
      }
    } else {
      // Internal error that shall never happen.
      // enqueueSnackbar('User model is not available', { variant: 'error' });
      // ---
      // Fetch the user model first.
      console.log('User model is not available at this point of time, fetch');
      const fetchedUserModel = await apiGetCurrentUser();
      if (fetchedUserModel) {
        console.log('Try again place a bet');
        handlePlaceBet(raceCardBetType, fetchedUserModel);
      }
    }
  };

  // ----------------------------------------------------------------------
  // React.useEffect.

  useEffect(() => {
    // Refresh interval time.
    const interval = setInterval(
      () => setRefreshTime(Date.now()),
      REFRESH_EVERY_SECONDS * 1000
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    fetchRaceCards();
  }, [fetchRaceCards, refreshTime]);

  useEffect(() => {
    if (activeEventModel) {
      fetchTote();
      console.log('ACTIVE EVENT MODEL', activeEventModel);
      const socket = Sockets.getCurrentSocket();

      if (socket) {
        //Sockets.setSocketForUser(activeEventModel.programId, updateModelAttributes);
        socket.subscribeEventId(
          `stream_${activeEventModel.programId}`,
          eventFromSocketListener
        );
        socket.subscribeEventId(activeEventModel.id, eventFromSocketListener);
        console.log('subscribeEventId', activeEventModel.id);
      }
    }
  }, [activeEventModel]);

  const Circle = ({ type, currentEvent }: CircleProps) => {
    const ColorCodeAttr = {
      X: '#D3544E',
      O: '#565DF5',
      R: '#FFA500',
      N: '#000000',
    };

    // const colorCode = type === 'X' ? '#D3544E' : tyo'#565DF5';
    return (
      <ListItem sx={{ padding: '4px', borderBottom: '1px solid #38404C' }}>
        <ListItemButton sx={{ padding: '0px' }}>
          <span
            onClick={() => setTrendStatsPopup(true)}
            style={{
              border: `1px solid ${ColorCodeAttr[type] || '#000000'}`,
              height: '10px',
              width: '10px',
              borderRadius: '100px',
            }}
          ></span>
        </ListItemButton>
      </ListItem>
    );
  };

  function getCircleType(type: string): 'X' | 'O' | 'R' | undefined {
    if (type === 'WINNER-1') {
      return 'X';
    } else if (type === 'WINNER-2') {
      return 'O';
    } else if (type === 'REFUND') {
      return 'R';
    }
  }

  function DisplayResults({ results }: { results: any }) {
    let gridData: JSX.Element[] = [];
    let rootActive = false;
    let listContent: JSX.Element[] = [];

    for (let i = 0; i < results.length; i++) {
      if (!rootActive && results[i]) {
        rootActive = true;
      }
      if (rootActive) {
        let currentEventType = getCircleType(results[i].eventResult);
        let nextEventType = getCircleType(results[i + 1]?.eventResult);

        listContent.push(
          <Circle key={i} type={currentEventType} currentEvent={results[i]} />
        );
        if (currentEventType !== nextEventType) {
          gridData.push(
            <List
              key={gridData.length}
              sx={{ padding: '0px', borderRight: '1px solid #38404C' }}
            >
              {listContent}
            </List>
          );
          listContent = [];
          rootActive = false;
        }
      }
    }
    // console.log(gridData);

    // Find the maximum number of children
    const maxChildren = Math.max(
      ...gridData.map(list => React.Children.count(list.props.children))
    );

    // Iterate over gridData and fill the missing children with '-'
    const filledGridData = gridData.map(list => {
      const childrenCount = React.Children.count(list.props.children);
      const missingChildrenCount = maxChildren - childrenCount;
      const filledChildren = React.Children.toArray(list.props.children);
      for (let i = 0; i < missingChildrenCount; i++) {
        filledChildren.push(<Circle key={`empty_${i}`} type='N' />);
      }
      return React.cloneElement(list, {}, filledChildren);
    });

    return <>{filledGridData}</>;
  }

  let trendObj =
    !!sportEventModels &&
    sportEventModels?.filter(eve =>
      ['WINNER-1', 'WINNER-2', 'REFUND'].includes(eve.eventResult)
    );

  // ----------------------------------------------------------------------
  console.log(stopBet, stopEventModel, 'stopmodel');
  const oddsReleased = stopEventModel?.hasOwnProperty('odds');
  const win_one = oddsReleased ? stopEventModel.odds['WIN-1'] : '0';
  const win_two = oddsReleased ? stopEventModel.odds['WIN-2'] : '0';

  const totalTrendX = sportEventModels?.filter(
    eve => eve.eventResult === 'WINNER-1'
  )?.length;
  const totalTrendO = sportEventModels?.filter(
    eve => eve.eventResult === 'WINNER-2'
  )?.length;
  const totalTrendC = sportEventModels?.filter(
    eve => eve.eventResult === 'REFUND'
  )?.length;

  // Helper function to calculate percentage difference
  const calculatePercentageDifference = (amount1: number, amount2: number) => {
    const difference = Math.abs(amount1 - amount2);
    const average = (amount1 + amount2) / 2;
    return (difference / average) * 100;
  };

  return (
    <div style={{ width: '100%' }}>
      <ScreenDiv
        style={
          {
            //  height:'100vh',
            // backgroundImage: isExpandedVideoStream ? 'url("https://arionplay-media.s3.ap-southeast-1.amazonaws.com/backgrounds/horses3.jpg")' : undefined,
          }
        }
      >
        <Box
          sx={{
            width: '100%',
            minHeight: '90px',
            display: 'flex',
            justifyContent: 'center',
            flex: '1',
            position: 'relative',
          }}
        >
          <TrendsCard>
            <TrendsView>
              <TrendsHead>
                <Grid
                  md={6}
                  sx={{ display: 'flex', width: '300px', alignItems: 'center' }}
                >
                  <Typography
                    sx={{
                      color: '#E0E1E3',
                      fontFamily: '"Roboto", sans-serif, Helvetica, Arial',
                      fontWeight: '500',
                      fontSize: '1rem',
                    }}
                  >
                    Trends
                  </Typography>
                </Grid>
                <Grid
                  md={6}
                  sx={{
                    display: 'flex',
                    width: '360px',
                    gap: '20px',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={{ display: 'flex' }}>
                    <label
                      style={{
                        background: '#D3544E',
                        borderRadius: '10px',
                        height: '18px',
                        width: '22px',
                        padding: '0px 6px',
                        fontSize: '11px',
                        fontFamily: 'google_sansbold',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      X
                    </label>
                    <label
                      style={{
                        fontSize: '12px',
                        fontFamily: 'google_sansbold',
                        paddingLeft: '5px',
                      }}
                    >
                      {totalTrendX}
                    </label>
                  </Typography>
                  <Typography sx={{ display: 'flex' }}>
                    <label
                      style={{
                        background: '#565DF5',
                        borderRadius: '10px',
                        height: '18px',
                        width: '22px',
                        padding: '0px 6px',
                        fontSize: '11px',
                        fontFamily: 'google_sansbold',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      O
                    </label>
                    <label
                      style={{
                        fontSize: '12px',
                        fontFamily: 'google_sansbold',
                        paddingLeft: '5px',
                      }}
                    >
                      {totalTrendO}
                    </label>
                  </Typography>
                  <Typography sx={{ display: 'flex' }}>
                    <label
                      style={{
                        background: '#606060',
                        borderRadius: '10px',
                        height: '18px',
                        width: '22px',
                        padding: '0px 6px',
                        fontSize: '11px',
                        fontFamily: 'google_sansbold',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      C
                    </label>
                    <label
                      style={{
                        fontSize: '12px',
                        fontFamily: 'google_sansbold',
                        paddingLeft: '5px',
                      }}
                    >
                      {totalTrendC}
                    </label>
                  </Typography>
                </Grid>
              </TrendsHead>
              <TrendsBody>
                <DisplayResults results={trendObj} />
              </TrendsBody>
            </TrendsView>
          </TrendsCard>
          {trendStatsPopup && (
            <div className='trendsresultpopup'>
              <div className='trendsresulhead'>
                <Typography
                  sx={{
                    fontSize: '20px',
                    lineHeight: '35px',
                    fontFamily: '"Roboto", sans-serif, Helvetica, Arial',
                    fontWeight: '700',
                    color: '#fff',
                  }}
                  variant='h5'
                  component='span'
                  style={{ marginLeft: 4 }}
                >
                  Trends
                </Typography>
                <div
                  className='closbtn'
                  onClick={() => setTrendStatsPopup(false)}
                >
                  <CloseIcon></CloseIcon>
                </div>
              </div>
              <div
                className='trandesresultbody'
                style={{
                  overflow: 'scroll',
                  maxWidth: '100%',
                  height: 'calc(100% - 100px)',
                }}
              >
                <List
                  style={{
                    overflowY: 'auto',
                    maxWidth: '100%',
                    padding: '10px',
                  }}
                  className='popoverview'
                >
                  {(sportEventModels || [])
                    .filter((raceEvent: RaceEventType) => {
                      return raceEvent.stopBetFlag === 'Y';
                    })
                    .map((raceEvent: RaceEventType, index: number) => {
                      //const openToBet = activeEventId === raceEvent.id && isActiveEventOpenToBet;
                      const openToBet = raceEvent.stopBetFlag === 'N';

                      return (
                        <ListItem
                          sx={{
                            borderBottom: '1px solid rgba(255, 255, 255, 0.06)',
                            padding: '0px 12px 0px 8px',
                          }}
                          key={raceEvent.id}
                          disablePadding={true}

                          // style={{
                          //   borderLeft: `3px solid ${openToBet ? theme.palette.success.main : 'transparent'}`,

                          // }}
                        >
                          <ListItemButton
                            sx={{ padding: '8px 0px', gap: '10px' }}
                            className='eventlistview'
                          >
                            <div
                              className='racestatuschange'
                              style={{
                                border: `2px solid ${
                                  openToBet
                                    ? theme.palette.success.main
                                    : '#FF6161'
                                }`,
                                borderRadius: '100%',
                                position: 'relative',
                              }}
                            >
                              <div
                                className='racestatuschangesub'
                                style={{
                                  background: `${
                                    openToBet
                                      ? theme.palette.success.main
                                      : '#FF6161'
                                  }`,
                                  height: '10px',
                                  width: '10px',
                                  borderRadius: '100%',
                                  position: 'relative',
                                  top: '2px',
                                  left: '2px',
                                }}
                              ></div>
                            </div>
                            <div>
                              {/* <div style={{border: `3px solid ${openToBet ? theme.palette.success.main : 'transparent'}`, height:'14px', width:'14px', borderRadius:'100%' }}></div> */}
                              <Stack direction='column' flexWrap='nowrap'>
                                <div
                                  style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '5px',
                                  }}
                                >
                                  {/* <div style={{border: `2px solid ${openToBet ? theme.palette.success.main : '#FF6161'}`, height:'14px', width:'14px', borderRadius:'100%' }}></div> */}
                                  <Typography
                                    sx={{
                                      fontSize: '12px',
                                      fontWeight: '500',
                                      color: 'rgba(255, 255, 255, 0.80)',
                                    }}
                                    variant='subtitle2'
                                    component='div'
                                  >
                                    {raceEvent.startTime} -
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: '12px',
                                      fontWeight: '500',
                                      color: 'rgba(255, 255, 255, 0.80)',
                                    }}
                                    variant='subtitle2'
                                    component='div'
                                  >
                                    {raceEvent.name.replace('Race-', 'Event ')}{' '}
                                    -
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: '12px',
                                      fontWeight: '400',
                                      color: 'rgba(255, 255, 255, 0.80)',
                                    }}
                                    variant='caption'
                                    component='div'
                                  >
                                    {raceEvent.venueName}
                                  </Typography>
                                  {/* <Typography variant="caption" component="div">
                        {raceEvent.id}  - {raceEvent.currentFlag} - {currentEvent}
                        </Typography> */}
                                </div>
                                <div
                                  style={{
                                    width: '100%',
                                    margin: '0px',
                                    lineHeight: '16px',
                                  }}
                                >
                                  {openToBet && (
                                    <Chip
                                      sx={{
                                        width: 'auto',
                                        padding: '0px',
                                        marginTop: '0px',
                                        background: 'none',
                                        justifyContent: 'flex-start',
                                        border: '0px',
                                        fontWeight: '500',
                                        fontSize: '12px',
                                        color: '#66B15F !important',
                                        paddingLeft: '0px',
                                        height: 'auto',
                                        lineHeight: '16px',
                                      }}
                                      size='small'
                                      variant='outlined'
                                      label='Open to bet'
                                      color='success'
                                      clickable={false}
                                      className='chiplabel'
                                    />
                                  )}
                                </div>
                              </Stack>
                              {raceEvent.eventResult && (
                                <>
                                  <Typography
                                    sx={{
                                      marginTop: '2px',
                                      color: '#FF6161',
                                      fontWeight: '500',
                                      fontSize: '12px',
                                      paddingLeft: '0px',
                                      lineHeight: '16px',
                                    }}
                                    variant='caption'
                                    component='div'
                                  >
                                    {raceEvent.eventResult !== 'REFUND' &&
                                      `Winner: ${
                                        raceEvent.eventResult === 'WINNER-1'
                                          ? 'X'
                                          : 'O'
                                      }`}
                                    {raceEvent.eventResult === 'REFUND' &&
                                      raceEvent.resultType === 'dead-heat' &&
                                      `TIE`}

                                    {raceEvent.eventResult === 'REFUND' &&
                                      raceEvent.resultType === 'void-null' && (
                                        <>
                                          CANCELLED - {raceEvent.resultMessage}
                                        </>
                                      )}
                                  </Typography>
                                </>
                              )}
                              {raceEvent.eventStatus && (
                                <>
                                  <Typography
                                    sx={{
                                      marginTop: '0px',
                                      color: '#FF6161',
                                      fontWeight: '500',
                                      fontSize: '12px',
                                      paddingLeft: '0px',
                                      lineHeight: '16px',
                                    }}
                                    variant='caption'
                                    component='div'
                                  >
                                    {raceEvent.odds
                                      ? `Closed at : X(${
                                          MathFloor(raceEvent.odds['WIN-1']) ||
                                          0
                                        }) , O(${
                                          MathFloor(raceEvent.odds['WIN-2']) ||
                                          0
                                        })`
                                      : ''}
                                    {raceEvent.odds === undefined
                                      ? `Closed at : X(0) , O(0)`
                                      : ''}
                                  </Typography>
                                </>
                              )}
                            </div>
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                </List>
              </div>
            </div>
          )}
        </Box>
        <BorderCard>
          <Grid container={true} spacing={1} sx={{ margin: '0px' }}>
            <Grid
              xs={12}
              md={12}
              sx={{
                marginTop: '0px',
                color: '#E0E1E3',
                fontFamily: '"Roboto", sans-serif, Helvetica, Arial',
                fontWeight: '500',
                fontSize: '1rem',
                marginLeft: '2px',
              }}
            >
              Enter bet amount
            </Grid>
            <BetCard className=''>
              <Grid item={true} xs={12} md={12}>
                <Stack direction='row' spacing={1}>
                  <FormControl
                    fullWidth={true}
                    size='small'
                    sx={{ height: '52px' }}
                  >
                    <OutlinedInput
                      id='bet-amount'
                      type='number'
                      placeholder='Enter bet amount'
                      startAdornment={
                        <InputAdornment
                          position='start'
                          sx={{ color: '#fff', height: '52px' }}
                        >
                          <div style={{ fontWeight: '400', fontSize: '20px' }}>
                            ₱
                          </div>
                        </InputAdornment>
                      }
                      sx={{
                        backgroundColor: '#40444569',
                        fontFamily: '"Roboto", sans-serif, Helvetica, Arial',
                        fontSize: '1.25rem',
                        fontWeight: '600',
                        height: '52px',
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: '1.5px solid #505455',
                          height: '57px',
                        },
                      }}
                      value={betAmount}
                      onChange={handleInputAmountChange}
                    />
                  </FormControl>
                  <Button
                    variant='contained'
                    size='small'
                    onClick={handleReset}
                    sx={{
                      padding: '4px 26px',
                      minWidth: '',
                      fontSize: '1rem',
                      fontWeight: '500',
                      background: '#737575',
                      fontFamily: '"Roboto", sans-serif, Helvetica, Arial',
                      border: '1.5px solid #b5b5b5',
                      height: '52px',
                      boxShadow: 'none',
                    }}
                  >
                    Reset
                  </Button>
                </Stack>
              </Grid>

              <Grid item={true} xs={12} md={12} sx={{ marginTop: '8px' }}>
                <Stack direction='row' spacing={1}>
                  <ToggleButtonGroup
                    fullWidth={true}
                    value={betAmount}
                    exclusive={true}
                    color='primary'
                    aria-label='bet amount'
                    sx={{
                      // backgroundColor: '#40444569',
                      gap: '10px',
                      // borderColor : theme.palette.action.disabledBackground,
                    }}
                  >
                    {amounts.map((amount: string) => (
                      <ToggleButton
                        key={amount}
                        value={amount}
                        className='amountbtn'
                        sx={{
                          fontFamily: '"Roboto", sans-serif, Helvetica, Arial',
                          fontSize: '1.25rem',
                          fontWeight: '600',
                          height: '44px',
                          border: '2px dotted #3C4243 !important',
                          strokeDasharray: '6 10',
                          borderRadius: '10px !important',
                        }}
                        onClick={(
                          event: React.MouseEvent<HTMLElement>,
                          newBetAmount: string | null
                        ) => {
                          setBetAmount(newBetAmount);
                        }}
                      >
                        {amount}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Stack>
              </Grid>
            </BetCard>
            <Grid
              xs={12}
              md={12}
              sx={{
                marginTop: '10px',
                color: '#E0E1E3',
                fontFamily: '"Roboto", sans-serif, Helvetica, Arial',
                fontWeight: '500',
                fontSize: '1rem',
                marginLeft: '2px',
              }}
            >
              {' '}
              Select “X” or “O” to place bet
            </Grid>
            <XOCard className=''>
              {((strAmount: string) => {
                const numAmount = parseInt(strAmount || '0') || 0;
                return (
                  <>
                    <Grid item={true} xs={4} md={4}>
                      <HorseBetButton
                        participants={
                          activeEventModel && activeEventModel.runners[0].Line1
                        }
                        type={BET_ODD}
                        odds={
                          selectedToteModel?.odds
                            ? selectedToteModel.odds['WIN-1']
                            : null
                        }
                        betAmountTotal={
                          selectedToteModel?.amounts
                            ? selectedToteModel.amounts['WIN-1']
                            : null
                        }
                        betAmount={numAmount}
                        onClick={handlePlaceBet}
                        disabled={!isActiveEventOpenToBet}
                      />
                    </Grid>
                    <Grid item={true} xs={4} md={4} className='openclose'>
                      <StyledRaceInfoButtonBase
                        onClick={handleOpenRaceCardsClick}
                        // aria-controls={openHorsesPopover ? 'account-menu' : undefined}
                        aria-haspopup='true'
                        // aria-expanded={openHorsesPopover ? 'true' : undefined}
                        sx={{ background: 'none' }}
                        disabled={!isActiveEventOpenToBet}
                      >
                        <Stack
                          direction='column'
                          alignItems='center'
                          spacing={1}
                          sx={{
                            width: '100%',
                            height: '100%',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Box
                            sx={{
                              width: 'auto',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              position: 'relative',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div>{getDotSVG(isActiveEventOpenToBet)}</div>
                              <Typography
                                sx={{
                                  width: '100%',
                                  fontSize: '1rem',
                                  lineHeight: '19px',
                                  fontFamily:
                                    '"Roboto", sans-serif, Helvetica, Arial',
                                  fontWeight: '500',
                                }}
                                variant='h5'
                                component='span'
                                style={{ marginLeft: 4 }}
                              >
                                {isActiveEventOpenToBet ? 'OPEN' : 'CLOSED'}
                              </Typography>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                width: '100%',
                                paddingTop: '4px',
                              }}
                            >
                              {isActiveEventOpenToBet && (
                                <Typography
                                  sx={{
                                    width: '100% ',

                                    lineHeight: '14px',
                                    fontFamily:
                                      '"Roboto", sans-serif, Helvetica, Arial',

                                    background: '#F17238',

                                    fontSize: '12px !important',
                                    fontWeight: '700',
                                    padding: '0px 10px',
                                    height: '15px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '6px',
                                  }}
                                  variant='h5'
                                  component='span'
                                  style={{ marginLeft: 4 }}
                                >
                                  Info
                                </Typography>
                              )}
                            </div>
                          </Box>
                          {activeEventModel?.eventResult && (
                            <div className='678'>
                              <Chip
                                sx={{
                                  width: '100%',
                                  padding: '2px',
                                  fontFamily:
                                    '"Roboto", sans-serif, Helvetica, Arial',
                                  fontWeight: '500',
                                  color: '#fff',
                                  background: 'rgba(84, 214, 44, 0.7)',
                                  border: '1px solid rgba(84, 214, 44, 0.7)',
                                  // margin: '4px',
                                }}
                                size='small'
                                variant='outlined'
                                label={`Winner: ${
                                  activeEventModel.eventResult === 'WINNER-1'
                                    ? 'X'
                                    : 'O'
                                }`}
                                color='success'
                              />
                            </div>
                          )}
                          <Typography
                            sx={{
                              width: '100%',
                              lineHeight: '14px',
                              fontWeight: '400',
                              fontSize: '0.813rem',
                              fontFamily:
                                '"Roboto", sans-serif, Helvetica, Arial',
                              marginTop: '0px- !important',
                            }}
                            variant='caption'
                            className='oddstext'
                          >
                            Odds will change based on betting activity
                          </Typography>
                          {isActiveEventOpenToBet && (
                            <>
                              {/* <StyledDividerDiv>&nbsp;</StyledDividerDiv> */}

                              <Typography
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  lineHeight: '16px',
                                  marginTop: '0px !important',
                                  fontFamily:
                                    '"Roboto", sans-serif, Helvetica, Arial',
                                }}
                                variant='caption'
                                className='racevs'
                              >
                                {/*{(activeEventModel?.activeRunners || '').split(',').length} */}
                                <span
                                  style={{
                                    fontFamily:
                                      '"Roboto", sans-serif, Helvetica, Arial',
                                    fontSize: '0.813rem',
                                    fontWeight: '500',
                                  }}
                                >
                                  {activeEventModel.venueName}
                                </span>
                                <span
                                  style={{
                                    fontFamily:
                                      '"Roboto", sans-serif, Helvetica, Arial',
                                    fontSize: '0.813rem',
                                    fontWeight: '500',
                                  }}
                                >
                                  {activeEventModel.name.replace(
                                    'Race-',
                                    'Event '
                                  )}
                                </span>
                                {userInfo &&
                                  userInfo.roles == 'gameoperator' && (
                                    <div>
                                      <p>
                                        % diff b/w the amounts:{' '}
                                        {selectedToteModel &&
                                          calculatePercentageDifference(
                                            selectedToteModel.amounts[
                                              'WIN-1'
                                            ] || 0,
                                            selectedToteModel.amounts[
                                              'WIN-2'
                                            ] || 0
                                          ).toFixed(2)}
                                        %
                                      </p>
                                    </div>
                                  )}
                              </Typography>
                              {/* <StyledDividerDiv>&nbsp;</StyledDividerDiv> */}
                              <Typography
                                sx={{
                                  width: '100%',
                                  fontFamily:
                                    '"Roboto", sans-serif, Helvetica, Arial',
                                  lineHeight: '16px',
                                  marginTop: '0px !important',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: '4px',
                                  borderTop:
                                    '1px solid rgba(255, 255, 255, 0.2)',
                                  paddingTop: '2px',
                                }}
                                variant='caption'
                                className='starttime'
                              >
                                <TimeIconSvg />{' '}
                                <span
                                  style={{
                                    fontFamily:
                                      '"Roboto", sans-serif, Helvetica, Arial',
                                    fontSize: '0.875rem',
                                    fontWeight: '600',
                                  }}
                                >
                                  {activeEventModel?.startTime || '-'}
                                </span>
                              </Typography>
                            </>
                          )}
                        </Stack>
                      </StyledRaceInfoButtonBase>
                    </Grid>
                    <Grid item={true} xs={4} md={4}>
                      <HorseBetButton
                        participants={
                          activeEventModel && activeEventModel.runners[1].Line1
                        }
                        type={BET_EVEN}
                        odds={
                          selectedToteModel?.odds
                            ? selectedToteModel.odds['WIN-2']
                            : null
                        }
                        betAmountTotal={
                          selectedToteModel?.amounts
                            ? selectedToteModel.amounts['WIN-2']
                            : null
                        }
                        betAmount={numAmount}
                        onClick={handlePlaceBet}
                        disabled={!isActiveEventOpenToBet}
                      />
                    </Grid>
                  </>
                );
              })(betAmount)}
            </XOCard>
          </Grid>
        </BorderCard>
        <Box
          style={{
            zIndex: 0,
            position: isExpandedVideoStream ? 'relative' : 'relative',
            overflow: 'hidden',
            // flexGrow: 1,
            width: '100%',
            height: 'auto',
            minHeight: 72,
          }}
        >
          {activeStreamSource ? (
            <VideoPlayer source={activeStreamSource} />
          ) : (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {activeEventModel && (
                <RaceVideo
                  venueId={activeEventModel.programId.toString()}
                  status={activeEventModel.eventStatus}
                />
              )}
              {activeEventModel?.programId === undefined ? (
                <div className='messageview'>No events available</div>
              ) : (
                ''
              )}
            </div>
          )}
        </Box>
        <Box sx={{ width: '100%' }}>
          <Stack
            direction='row'
            justifyContent='space-between'
            spacing={1}
            className='gameoddeven'
            sx={{
              position: 'absolute',
              top: 16,
              right: 0,
              width: '100%',
              padding: '0px 16px',
              minHeight: '2rem',
            }}
          >
            <StyledRaceInfoButtonBase
              onClick={handleOpenEventsClick}
              aria-controls={
                Boolean(eventsAnchorEl) ? 'events-menu' : undefined
              }
              aria-expanded={Boolean(eventsAnchorEl) ? 'true' : undefined}
              aria-haspopup='true'
              disabled={!sportEventModels?.length}
              sx={{
                width: 'auto',
                minWidth: '80',
                minHeight: '26px',
                maxWidth: '660px',
              }}
              className='noeventlist'
            >
              <Stack
                direction='row'
                alignItems='center'
                spacing={1}
                className='12'
                sx={{ width: '100%' }}
              >
                <div className='gameoddevenview'>
                  <div className='eventsvgicon'>
                    {getDotSVG(isActiveEventOpenToBet)}
                  </div>
                  <div className='eventslist'>
                    {
                      <Typography variant='caption' className='eventnametext'>
                        {sportEventModels?.length
                          ? activeEventModel
                            ? `${activeEventModel.startTime} - ${
                                activeEventModel.venueName
                              } - ${activeEventModel.name.replace(
                                'Race',
                                'Event'
                              )}`
                            : 'No active event'
                          : 'No available events'}
                      </Typography>
                    }
                    {
                      <Typography variant='caption' className='closebetview'>
                        {sportEventModels?.length
                          ? activeEventModel && activeEventModel.odds
                            ? `${
                                activeEventModel.eventStatus === 'FINAL'
                                  ? activeEventModel.eventResult === 'WINNER-1'
                                    ? 'Winner :  X'
                                    : 'Winner :  O'
                                  : ''
                              } Closed at : X(${
                                MathFloor(activeEventModel.odds['WIN-1']) || 0
                              }) , O(${
                                MathFloor(activeEventModel.odds['WIN-2']) || 0
                              }) `
                            : ''
                          : 'No available events'}
                      </Typography>
                    }
                  </div>
                </div>
              </Stack>
              <KeyboardArrowDown></KeyboardArrowDown>
            </StyledRaceInfoButtonBase>
            {/* {!isMobile &&
            <Fab
              size="small"
              color="info"
              aria-label="Expand video"
              onClick={() => setIsExpandedVideoStream(!isExpandedVideoStream)}
              style={{ padding: 8 }}
            >
              { isExpandedVideoStream ? <CollapseIconSvg /> : <ExpandIconSvg /> }
            </Fab>
          } */}
          </Stack>
        </Box>
      </ScreenDiv>
      {stopBet && (
        <div className='betcloseevent'>
          <div className='betclosepopup'>
            <div className='closbtn' onClick={() => setStopBet(!stopBet)}>
              <CloseIcon></CloseIcon>
            </div>
            <div className='closeicon'>
              <ClosePopSVG />
            </div>

            <Typography
              variant='h4'
              sx={{
                fontSize: '18px !important',
                fontWeight: '700',
                lineHeight: '26px',
                paddingTop: '15px',
              }}
            >
              {`${stopEventModel.venueName}`} -{' '}
              {stopEventModel.name.replace('Race-', 'Event ')}
            </Typography>
            <Typography
              variant='h5'
              sx={{
                fontSize: '18px !important',
                fontWeight: '700',
                lineHeight: '26px',
                paddingBottom: '10px',
              }}
            >
              Closed at
            </Typography>

            <Typography
              variant='h6'
              sx={{
                fontSize: '24px !important',
                fontWeight: '700',
                lineHeight: '26px',
              }}
            >
              X : {MathFloor(win_one) || 0} O : {MathFloor(win_two) || 0}
            </Typography>
          </div>
        </div>
      )}
      {resultPopover && stopEventModel?.eventResult && (
        <div className='betcloseevent'>
          <div className='betclosepopup betsucesspopup'>
            <img
              style={{ height: '260px', position: 'absolute', top: '30px' }}
              src='https://www.northalley.com/projects/arionplay/animation.gif'
              alt=''
            />
            <div
              className='closbtn'
              onClick={() => setResultPopover(!resultPopover)}
            >
              <CloseIcon></CloseIcon>
            </div>
            <div className='croenicons'>
              {' '}
              <CrownPopSVG />
            </div>

            <Typography
              variant='h4'
              sx={{
                fontSize: '16px !important',
                fontWeight: '700',
                lineHeight: '26px',
                paddingTop: '15px',
              }}
            >
              {`${stopEventModel.venueName}`} -{' '}
              {stopEventModel.name.replace('Race-', 'Event ')}
            </Typography>
            <Typography
              variant='h5'
              sx={{
                fontSize: '16px !important',
                fontWeight: '700',
                lineHeight: '26px',
                paddingBottom: '15px',
              }}
            >
              {stopEventModel.eventResult !== 'REFUND' && ` Winner is `}
            </Typography>

            <Typography
              variant='h6'
              sx={{
                fontSize: '28px !important',
                fontWeight: '700',
                lineHeight: '26px',
              }}
            >
              {stopEventModel.eventResult === 'WINNER-1' &&
                ` X : ${MathFloor(win_one) || 0}`}
              {stopEventModel.eventResult === 'WINNER-2' &&
                ` O : ${MathFloor(win_two) || 0}`}

              {stopEventModel.eventResult === 'REFUND' &&
                stopEventModel.resultType === 'dead-heat' &&
                `TIE`}

              {stopEventModel.eventResult === 'REFUND' &&
                stopEventModel.resultType === 'void-null' && (
                  <>
                    <Typography
                      variant='h4'
                      sx={{
                        textAlign: 'center',
                        fontSize: '16px !important',
                        fontWeight: '700',
                        lineHeight: '26px',
                      }}
                    >
                      CANCELLED
                    </Typography>
                    <Typography
                      variant='h4'
                      sx={{
                        textAlign: 'center',
                        fontSize: '14px !important',
                        fontWeight: '700',
                        lineHeight: '26px',
                      }}
                    >
                      {stopEventModel.resultMessage}
                    </Typography>
                  </>
                )}
            </Typography>
          </div>
        </div>
      )}

      {betplacedPopover && (
        <div className='betcloseevent'>
          <div
            style={{
              background: 'none',
              border: 'none',
              backdropFilter: 'none',
            }}
            className='betclosepopup betsucesspopup'
          >
            <img
              src='https://static-web.fra1.cdn.digitaloceanspaces.com/arionplay/Arionplaybetplacedanimation.gif'
              alt=''
            />
          </div>
        </div>
      )}

      <HorsesPopover
        anchorEl={horsesAnchorEl}
        handleClose={handleCloseRaceCards}
        runners={activeEventModel?.runners}
      />

      <SportEventsPopover
        anchorEl={eventsAnchorEl}
        handleClose={handleCloseEvents}
        sportEventModels={sportEventModels}
        activeEventId={activeEventModel?.id}
        isActiveEventOpenToBet={isActiveEventOpenToBet}
        onChangeEvent={onChangeEvent}
      />

      <SportEventsBetConfirmPopover
        raceBet={placeRaceBet}
        handleCancel={() => setPlaceRaceBet(null)}
        handleConfirm={handleSendBet}
      />
    </div>
  );
};

export default GameOddEven;
