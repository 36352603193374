import {
  FormGroup,
  Label,
  Input,
  Button,
  ButtonSpinner,
  InputGroup,
} from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import React, { useState } from 'react';
import { Grid } from '@mui/material/';
//import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';
import { hasKey } from '@/shared/methods';
import AuthService from '@/services/AuthService';

import {
  StyledWrap,
  StyledTitle,
  StyledDesc,
  StyledPhoneText,
  StyledAlertMessage,
  StyledAlertMessageSucess,
  StyledButtonContainer,
} from './style';
import { AlertWarningiconSvg, KycVerifiedIconSvg } from '@/shared/svg';

interface ChildProps {
  parentModalHandlerFunction: any;
}

const DepositForm = (props: ChildProps) => {
  //const dispatch = useAppDispatch();
  const [message, setMessage] = useState('');
  const [isNotValid, setIsNotValid] = useState(false);
  const [isamountValidation, setIsamountValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userInfo } = useAppSelector(state => state.auth);
  const { selectedUserWallet } = useAppSelector(state => state.wallet);
  const [messagestatus, setMessageStatus] = useState('');
  const minAmount = 100;
  // console.log('Deposit', { userInfo,selectedUserWallet});
  let selectedUserWalletId: string | null = null;
  if (selectedUserWallet) {
    selectedUserWalletId = selectedUserWallet.id;
  } else if (userInfo) {
    selectedUserWalletId = userInfo.selectedWalletId;
  }

  if (!selectedUserWalletId) {
    // This shall never happen!
    // TODO: Send fucking message to Dawid.
    alert('Apologies, we are maintain deposit function');
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IDepositForm>();

  const onSubmit: SubmitHandler<IDepositForm> = async data => {
    var amount = data.amount;
    if (typeof amount !== 'number' && isNaN(amount)) {
      setIsamountValidation(true);
      setMessage('Amount must be > 0, not 0');
      return;
    }

    if (data.amount < minAmount) {
      setIsamountValidation(true);
      setMessage(
        `The minimum amount required is ${minAmount}. Please add an <br/> amount of ${minAmount} or above to proceed.`
      );
      return;
    }
    setLoading(true);
    const response = await AuthService.deposit(data, selectedUserWalletId);
    console.log(response);
    if (response.data.error === null) {
      window.open(response.data.url, '_self');
    } else {
      setIsamountValidation(true);
      setMessageStatus('error');
      setLoading(false);
      setIsNotValid(true);
      setMessage(response.data);
    }
  };

  return (
    <StyledWrap style={{ width: '' }}>
      <StyledTitle>Deposit</StyledTitle>
      <StyledDesc>
        Add the amount below to deposit in your Wallet account.
      </StyledDesc>
      <form
        className='loginform'
        action='#'
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {/* {isNotValid ? (
          messagestatus == 'error' ? (
            <StyledAlertMessage style={{border: '1px solid #DC6803', background:'rgba(255, 255, 255, 0.05)', fontWeight:'400', fontSize:'13px', color:"#FEFEFE", gap:'10px', lineHeight:'17px', padding:'8px', }}>
              <AlertWarningiconSvg /> {message}
            </StyledAlertMessage>
          ) : (
            <StyledAlertMessageSucess>
              <KycVerifiedIconSvg /> {message}
            </StyledAlertMessageSucess>
          )
        ) : null} */}
        {isamountValidation ? (
          <StyledAlertMessage
            style={{
              border: '1px solid #DC6803',
              background: 'rgba(255, 255, 255, 0.05)',
              fontWeight: '400',
              fontSize: '13px',
              color: '#FEFEFE',
              gap: '10px',
              lineHeight: '17px',
              padding: '8px',
              fontFamily: 'Google Sans',
            }}
          >
            <AlertWarningiconSvg />
            <span
              dangerouslySetInnerHTML={{ __html: message }}
              style={{ fontFamily: 'Google Sans' }}
            ></span>
          </StyledAlertMessage>
        ) : null}
        <div>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} className='no_padding'>
              <Label display='block' mb='5px' htmlFor='email'>
                Amount
                {/* <span
                  style={{
                    color: '#CECECE',
                    fontSize: '13px',
                    fontWeight: '400',
                    width: '100%',
                    textAlign: 'right',
                    fontFamily: 'Google Sans',
                  }}
                >
                  ( Minimum ₱{minAmount} )
                </span> */}
              </Label>

              <FormGroup>
                <StyledPhoneText>&#8369;</StyledPhoneText>
                <InputGroup>
                  <Input
                    className='mobilenumber'
                    type='text'
                    id='amount'
                    name='amount'
                    placeholder='Enter Amount'
                    feedbackText={errors?.amount?.message}
                    state={hasKey(errors, 'amount') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'amount')}
                    {...register('amount', {
                      required: 'Amount is required',
                      pattern: {
                        value: /^[0-9]+$/,
                        message: 'Only integers are allowed',
                      },
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Grid>
          </Grid>
        </div>
        <StyledButtonContainer>
          <Button type='submit' color='brand2' className='signin_btn'>
            {loading ? <ButtonSpinner /> : null}Continue
          </Button>
        </StyledButtonContainer>
      </form>
    </StyledWrap>
  );
};

export default DepositForm;
