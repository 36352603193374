import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, styled } from '@mui/material';
import BorderCard from '@/components/BorderCard';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

const GameCardImageWrapperDiv = styled('div')(({ theme }) => ({
  border: '1px solid #000',
  overflow: 'hidden',
  borderRadius: 10,
}));

const GameNameDiv = styled('div')(({ theme }) => ({
  color: '#A3ADC2',
  fontSize: 12,
  fontFamily: 'google_sansmedium, sans-serif',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  padding: 10,
}));

const GameCategoryDiv = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: -1,
  right: -1,
  padding: '1px 10px',
  fontFamily: 'google_sansmedium, sans-serif',
  fontSize: 10,
  zIndex: 1,
  color: '#ffffff',
  borderRadius: '0px 0px 0px 8px',
  borderBottom: '1px solid #384766',
  borderLeft: '1px solid #384766',
  background: 'linear-gradient(180deg, #182740 0%, #02173A 100%)',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '81px',
  textOverflow: 'ellipsis',

  '@media (max-width: 600px)': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '84px',
    textOverflow: 'ellipsis',
  },
}));

export default function GameCard({ detail }: { detail: any }) {
  return (
    // <Card sx={{height: 407}}>
    //   <BorderCard>
    //     <CardActionArea component={Link} to={detail.url} sx={{padding:2}}>
    //       <CardMedia
    //         sx={{ height: 214,borderRadius: 1}}
    //         image={detail.img}
    //         title={detail.title}
    //       />
    //       <CardContent sx={{paddingTop:2,paddingBottom:1,paddingRight:0,paddingLeft:0}}>
    //         <Typography gutterBottom variant="h5" component="div">
    //           {detail.title}
    //         </Typography>
    //         <Typography variant="body2" color="text.secondary" sx={{height: 44}}>{detail.desc}</Typography>
    //         <Button variant="outlined" style={{marginTop: 20}}>
    //           <img width={20} height={20} style={{marginRight: 6}}
    //             src='https://static-web.fra1.cdn.digitaloceanspaces.com/arionplay/arionplay-circle-right.svg'
    //             alt='' />
    //           {detail.title==="Horse Racing" ? 'Play Now' :'Coming Soon'}
    //         </Button>
    //       </CardContent>
    //     </CardActionArea>
    //   </BorderCard>
    // </Card>

    <BorderCard>
      <CardActionArea component={Link} to={`/game/provider/${detail.id}`}>
        <GameCardImageWrapperDiv>
          <CardMedia
            component='img'
            loading='lazy'
            alt={detail.title}
            image={detail.img}
          />
          <GameNameDiv>{detail.title}</GameNameDiv>
        </GameCardImageWrapperDiv>
      </CardActionArea>
    </BorderCard>
  );
}
